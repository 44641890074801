export default function useDateFormat(timestamp) {
  const date = new Date(timestamp * 1000);

  if (!isNaN(date)) {
    const day = date.getDate().toString();
    const month = (date.getMonth() + 1).toString();
    const year = date.getFullYear();
    return `${day}/${month} ${year}`;
  }
}
